import PropTypes from 'prop-types';

function Tooth18({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 18)
    : lowerArch;
  const variable = designType.toothChart ? 18 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[18]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M3032.4 3190.9c32.1 14.9 64.9 24.4 95 56.6 24.9 19.6 43.4 54.8 47.7 124.9 2.1 49.2 14 97.2-18.6 150.9 -28 23.6-10.8 31.6-86.9 71.8 -39.2 16.4-67.3 52.6-127.5 31.4 -29.1-8.2-39-5-88.9-25.6 -21.1-4.8-38.7-16.9-48.4-44.7 -10.5-18-19.3-42.9-25.2-79.2 2-31.4 6.8-44.3 11-60.4 6.8-42.2 4.3-59.8-0.2-72.4 -0.5-21.3 3.8-42.5 17.1-63.8 7-23 11.6-44.8 52.4-70C2897.2 3182.4 2921.3 3161.4 3032.4 3190.9z"
      />
      <path
        className="st1"
        d="M2961.9 3597.1c-14.5-6.9-14.7-19-58.7-15.4 -10.6-1.4-21.3-1.6-31.9-16.3l-23.3-14.6 -19.9-17.2c-15.5-16.9-19.1-35.1-13.9-54.3 2.7-21.3 7.1-47 3.3-50.9 -2-13.2 2-34.6 6.7-56.9 -2.2-28.8-22.6-24.8 4-105.2 1.7-17.1 10.8-25.9 27.5-25.9 13.6-2.7 17.3-9 34.4-22.5 12.2-7.2 18.5-14.4 45.6-21.7 20.7 2.6 41.4-4.3 62.1 12.8l36.3 32.7c29.3 24.3 32.4 37.7 34.6 50.8 8.1 19.5 5 39.1 1.9 58.6 -0.7 16.7-6.4 30.5-23.2 38 8 9.6 17.1 14.2 20.8 45.6 8.6 54.6-4.6 68.6-13.6 90.6 -17.5 25.4-34.9 44.5-52.4 65.7"
      />
      <path
        className="st1"
        d="M2913.6 3253.3c15.7 10.7 28 27.4 34.6 54.2 -2.8 21-7.7 37.9-12.8 54.3 -4.3 22.7-16.2 21.4-10.1 76.8 10.8 10.4 13.4 18.2 10.4 24.1 -19.5 41.5-12.7 78.1 18.4 110.3"
      />
      <path
        className="st1"
        d="M2884.1 3523.2c9.6-20.8 20.5-40.9 48.1-52.7 3.8-11.8 16.8-16.4 41.3-12.2"
      />
      <path
        className="st1"
        d="M3013.1 3384.9c-39.1-3.3-73.5-3.8-85.3 8.8"
      />
      <path
        className="st1"
        d="M2829.5 3408.7c30.6-8.5 51.8-25.3 99.1-19.2"
      />
      <path
        className="st1"
        d="M2840.6 3370.7l42.3 22.3"
      />
      <path
        className="st1"
        d="M3009.4 3290.1c-28.1 5.5-47.7 11-62 16.5"
      />
      <path
        className="st1"
        d="M2972.2 3239.3c-11.1 21.9-25.1 43.7-23.1 65.6"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="2800"
          y="3200"
          width="360"
          height="400"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth18.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth18;
