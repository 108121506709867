import PropTypes from 'prop-types';

function Tooth26({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.includes(26)
    : lowerArch;
  const variable = designType.toothChart ? 26 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[26]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className="st1"
        d="M1438.1 4684.9c-22.1 32.1-83.3 64.1-14 96.5"
      />
      <path
        className="st1"
        d="M1489.7 4718.6l15.3 74.2c10 35.7 20.1 21 30.2 0.1"
      />
      <path
        className="st1"
        d="M1359.5 4746.7c1.6 26.2-0.9 54.9 31.7 61.3 27.7 9.6 62.5 19.1 73.2 28.6 45.9 25.3 50.2 12.1 68.9 12.2"
      />
      <path
        className={className}
        d="M1527.8 4663.6c-16.4-45.8-56.4-46.3-83.6-24.4l-38.9 49 -45.8 43.8c-6.7 20.5-24.6 19.3-3.7 93.1 12.6 39.9 37.8 57.8 68.8 65.7 39.7 11.4 73.7 4.1 104.3-14.4 20.9-21.5 53.9-31.7 45-80.9 -8.9-22.2-9.3-27.3-28.3-69.9C1539.8 4705.1 1533.8 4694.9 1527.8 4663.6L1527.8 4663.6z"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="1350"
          y="4650"
          width="220"
          height="220"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth26.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth26;
