import PropTypes from 'prop-types';

function Tooth25({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 25)
    : lowerArch;
  const variable = designType.toothChart ? 25 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[25]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M1627.6 4732.8c-13 28.5-22.2 60.1-45.9 80.1 -10.6 10.7-19.8 24.3-8 80.2 12.3 23.3 33.3 36.9 66.3 37.2 26.6-5 49.5-12.1 91.4-8.4 20.2-4.9 38.5 1.7 63-30.9 8.1-15.4 20.7-25.1 0.2-75.9l-62.7-83.8c-17-12.7-18.9-32.2-58.5-34.6C1642.4 4697.7 1636.6 4716.3 1627.6 4732.8z"
      />
      <path
        className="st1"
        d="M1586.7 4885.3c17.1 2.9 33.3 8.5 52.6 2.7l31 2.7c21.8 3.2 40.6 0.3 60.3-0.7 29.9-1.6 60.7-2.7 52.6-22.3"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="1580"
          y="4700"
          width="220"
          height="220"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth25.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth25;
