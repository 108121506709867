import PropTypes from 'prop-types';

function Tooth32({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(32) : lowerArch;
  const variable = designType.toothChart ? 32 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[32]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M474.2 3078c-5.3-37.4-29.3-41-10-123.3 10-58.7 38.8-107.2 103.8-135.9 39.3-5.6 75.2-33.7 120.7 0.3 13.3 11.6 23.1 17.8 31.8 22.5 18.1 18.8 53.2 19.9 70.5 91.6 0 27.3-6.7 37.9 5.8 96.6 3.9 22.6 15.4 36.8 2.4 78.5 -14.1 19.3-12.8 39.7-56.2 56.7 -11.9 11-106.5 21.4-137.1 20.3 -30.6-1-29.1 9.4-75.8-34.7C511.5 3127.2 494.7 3117.7 474.2 3078L474.2 3078z"
      />
      <path
        className="st1"
        d="M691.2 2848.4c-35-52.2-74-29-113.8 7.5 -22.6 27.2-29.6 55.8-26.9 85.3 1 17.7 8.7 29.7 25.8 33.7 -11.3 6.3-15.8 18.1-15.6 33.6l15.3 89.7c14.6 37 26.1 36.8 38.7 48.4 6.7 3.4 13.5 6.7 22.4 7.8 12.4-4.2 24.7-6.7 37.2-42.1 -10.5-3.4 33.2-20.2 1-53.4 -10.4-11.3 24.7-48.1 4.5-78.4 -10.3-33.4-20.2-62.2-28.3-75.1 21.8-3.5 15.3-23.4 22.5-35.3"
      />
      <path className="st1" d="M625.5 2898.3l25.8 7" />
      <path className="st1" d="M727.1 2973.5c-13.7 6.2-25.5 16.7-45.7 7.6" />
      <path className="st1" d="M643.1 3083.7c16.1-2.5 28.2-10.7 32.8-29.2" />
      <path className="st1" d="M613.3 2974.1c21.4-4.1 44.9-16.8 62.1-4.1" />
      <path
        className="st1"
        d="M680.9 3143.2c13.6-0.4 26.5 0.7 42.3-4.2 22.5-25.2 30.6-25.2 44.9-36.1 2.7-0.8 31.1-30.4 10.5-75 -11.3-22.8-14.1-30.7-9.4-47.4 -11.1-39-5.1-47.8-7.6-71.6"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="460"
          y="2800"
          width="320"
          height="380"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth32.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth32;
