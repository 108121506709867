import { gql } from '@apollo/client';

export default gql`
  mutation createSimpleOrder($input: SimpleOrderCreateInput!) {
    createSimpleOrder(input: $input) {
      order {
        intOrderId
        name
        turnaroundTimeId
        userId
        labLocationId
        organization {
          id
        }
        instructions
        requiresDesignApproval
        softwareVersion
        orderItems {
          units
          arches
          teeth
          guideCodes
          nestedTeeth
          nestedArches
          splitFile
          materialName
          modifiers {
            id
          }
          manufacturerOrganizationId
          designTypeId
        }
      }
      errorMessages {
        message
        path
      }
    }
  }
`;
