import { gql } from '@apollo/client';

const DesignTypeInfo = gql`
  fragment DesignTypeInfo on DesignType {
    id
    name
    slug
    designApproval
    acceptRushDesigns
    requiresApprovalUpload
    model
    disableInHouse
    toothChart
    archChart
    billByTooth
    billByArch
    clearAlignerDesignType
    showStlFiles
    showQcImages
    attachmentFiles
    approvalLink
    manufacturerFiles
    designTypeCategoryId
    enableForZoo
    enableSimpleUploader
    designTypeCaseFolders {
      id
      designTypeId
      caseFolderId
      required
    }
    renameStl
    mappedNames
    designTypeCategory {
      id
      name
      slug
    }
    description
    caseFolders {
      id
      name
      slug
      folderName
      mimeTypes
      allowMultiple
    }
  }
`;

export default DesignTypeInfo;
