import PropTypes from 'prop-types';

function Tooth20({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 20)
    : lowerArch;
  const variable = designType.toothChart ? 20 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[20]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M2673.5 4066c-84.6 42.1-83.4 64.7-86 96.8 6.4 38.4 4.7 52.7 4.5 71.5 -1.1 39.5 23.5 49.9 39 70.6 49.7 49.8 76.2 33.7 111.3 42 32.3-5 51.9-9.9 70.7-14.8 52.6-12.8 48-32.5 68-49.3 25.6-34.3 27.8-78.2 14.3-128.5 -11.1-29.9-33.6-52.3-57.1-74l-57.8-29.2C2735.9 4032.7 2685.1 4057.9 2673.5 4066L2673.5 4066z"
      />
      <path
        className="st1"
        d="M2752.8 4063.2c18.4 3.7 40.8 6.5 48.4 47.3 -0.2 17.8 5.5 31.6 12.2 44.8 8 42.6-12.9 70.8-31.6 100.1 -14.5 24.5-32.5 27-49.9 32.9 -19 10-33.3 3.3-43.2-18.9 -6.1-12.6-7.3-31.3-21.6-33.6 -6.5-11.4-7.3-21-7-30.2 8.2-15.3 1.5-36.5-1-56 33.5-1.7 41.7-28.5 45.5-59.6C2720.1 4084.1 2735.1 4082.1 2752.8 4063.2L2752.8 4063.2z"
      />
      <path
        className="st1"
        d="M2712.8 4251.2c-19.8-18-19.5-49.5-19.2-81 12.2-52.8 34.3-78 69.7-66.6"
      />
      <path
        className="st1"
        d="M2674.7 4217.7c1.6-27.1 9.8-39.2 20.6-45.7"
      />
      <path
        className="st1"
        d="M2724.4 4088.3l-2.5 24.1"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="2600"
          y="4075"
          width="300"
          height="260"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth20.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth20;
