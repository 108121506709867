import PropTypes from 'prop-types';

function Tooth7({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(7) : upperArch;
  const variable = designType.toothChart ? 7 : 'upper';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[7]?.includes(guideCodeId);
  const className = `st2 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M1197.9 608.4c133.4-87.6 106.1-62 163.1-81.5 19.9-7.1 36.4-8.2 44.8 5.3l31 31.1c21 26.4 19.6 32 22.3 41.4 9.9 81.7-1.5 80.7-3.8 115.5 -15.3 29.6-33.7 56-64.8 69.7 -19.2 13.6-45.9 12.2-74.2 7.6l-46.5-11.3c-18.2 2.7-34-9.4-49.9-20 -33.7-23.7-42.5-57.3-54.1-89.8 -2.3-21 3.6-39.6 17.4-56L1197.9 608.4z"
      />
      <path
        className="st2"
        d="M1235.4 756.8c-17.9-27.8-46.6-50.3-11.8-104.3 31.9-24.9 56.8-32.6 84.6-47.2 23.9-12 47.6-23.7 64.7-22.2 71.2-6.8 41.9 10.2 61.2 15.7"
      />
      <path className="st2" d="M1384.9 631.3c6.1 31.9 11.8 64.6 29.1 74.2" />
      <path className="st2" d="M1344 763.9c2.5-27.2-37.1-38.7-69.7-52.8" />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="1200"
          y="530"
          width="240"
          height="250"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth7.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth7;
