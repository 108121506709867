import { useApolloClient } from '@apollo/client';
import { OrthoViewer } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import {
  latestOtpFile,
  matchedConvertedFiles,
} from '../../../../../../../../transforms';

function ElementActionsConvertedTreatmentFiles({
  selectedItems,
  redesignCount,
  files,
  containerStyle: { height = '90vh' } = {},
  iprs = null,
}) {
  const client = useApolloClient();

  const [otpData, setOtpData] = useState();
  const [newOtpVersion, setNewOtpVesrion] = useState(false);

  const parsedFiles = useMemo(
    () => matchedConvertedFiles(selectedItems, redesignCount),
    [redesignCount, selectedItems],
  );

  useEffect(() => {
    let active = true;

    async function loadOtp() {
      setOtpData(undefined);

      const otpRes = await Promise.resolve(
        latestOtpFile(files, redesignCount, client, '.otp'),
      );

      if (!active) {
        return;
      }

      setNewOtpVesrion(false);
      setOtpData(otpRes);
    }

    loadOtp();

    return () => {
      active = false;
    };
  }, [client, files, redesignCount]);

  return otpData ? (
    <OrthoViewer
      files={parsedFiles.objFiles}
      diffFiles={parsedFiles.diffFiles}
      otpData={otpData}
      containerStyle={{ height }}
      iprs={iprs}
      newOtpVersion={newOtpVersion}
    />
  ) : (
    <div
      id="canvas-container"
      style={{
        height,
        width: '100%',
        backgroundColor: '#121212',
        paddingBottom: 134,
        overflow: 'hidden',
        position: 'relative',
      }}
    />
  );
}

ElementActionsConvertedTreatmentFiles.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  redesignCount: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  containerStyle: PropTypes.object,
  iprs: PropTypes.object,
};

export default ElementActionsConvertedTreatmentFiles;
