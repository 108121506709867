import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ModifierModal from './ModifierModal';
import ModifierSelectionPopup from './ModifierSelectionPopup';

function ModifierSelection({ ...props }) {
  const {
    index,
    values: { orderItems, businessUnitName },
    data: { designTypes },
    setShouldShow,
  } = props;
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [modifierGroups, setModifierGroups] = useState(null);

  function modifierModalOpen(groups) {
    setModifierGroups(groups);
    setShowModifierModal(true);
  }

  function modifierModalClose() {
    setShowModifierModal(false);
    setModifierGroups(null);
  }
  if (businessUnitName === '') {
    return 'Please first select a category';
  }
  if (orderItems[index].designTypeId === '') {
    return 'Please select your indication';
  }
  const selectedDesignType = designTypes[businessUnitName].find(
    (dT) => dT.id === orderItems[index].designTypeId,
  );
  if (selectedDesignType === '') {
    return 'Please select your indication';
  }
  useEffect(() => {
    if (
      !selectedDesignType?.modifierGroups?.some(
        ({ modifiers }) => modifiers.length > 0,
      )
    ) {
      setShouldShow(false);
    }
  }, []);
  return (
    <>
      <ModifierSelectionPopup
        modifierGroups={selectedDesignType.modifierGroups}
        currentOrderItem={orderItems[index]}
        index={index}
        modifierModalOpen={modifierModalOpen}
      />
      <ModifierModal
        {...props}
        modifierModalOpen={modifierModalOpen}
        modifierModalClose={modifierModalClose}
        showModifierModal={showModifierModal}
        modifierGroups={modifierGroups}
      />
    </>
  );
}

ModifierSelection.propTypes = {
  selectedDesignType: PropTypes.object,
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  data: PropTypes.object,
  setShouldShow: PropTypes.func,
};

export default ModifierSelection;
