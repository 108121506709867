import PropTypes from 'prop-types';
import './DentalChart.scss';
import {
  Arches,
  FdiNumbers,
  PalmerNumbers,
  Tooth1,
  Tooth10,
  Tooth11,
  Tooth12,
  Tooth13,
  Tooth14,
  Tooth15,
  Tooth16,
  Tooth17,
  Tooth18,
  Tooth19,
  Tooth2,
  Tooth20,
  Tooth21,
  Tooth22,
  Tooth23,
  Tooth24,
  Tooth25,
  Tooth26,
  Tooth27,
  Tooth28,
  Tooth29,
  Tooth3,
  Tooth30,
  Tooth31,
  Tooth32,
  Tooth4,
  Tooth5,
  Tooth6,
  Tooth7,
  Tooth8,
  Tooth9,
  UnsNumbers,
} from './DentalChartSvg';

function DentalChart({ numberingSystem, designType, nested = false, ...rest }) {
  const props = { numberingSystem, designType, nested, ...rest };
  return (
    <div className={nested ? 'chart-wrapper__nested' : 'chartWrapper'}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3627 5335.7">
      <title>svg</title>
        <Tooth1 {...props} />
        <Tooth2 {...props} />
        <Tooth3 {...props} />
        <Tooth4 {...props} />
        <Tooth5 {...props} />
        <Tooth6 {...props} />
        <Tooth7 {...props} />
        <Tooth8 {...props} />
        <Tooth9 {...props} />
        <Tooth10 {...props} />
        <Tooth11 {...props} />
        <Tooth12 {...props} />
        <Tooth13 {...props} />
        <Tooth14 {...props} />
        <Tooth15 {...props} />
        <Tooth16 {...props} />
        <Tooth17 {...props} />
        <Tooth18 {...props} />
        <Tooth19 {...props} />
        <Tooth20 {...props} />
        <Tooth21 {...props} />
        <Tooth22 {...props} />
        <Tooth23 {...props} />
        <Tooth24 {...props} />
        <Tooth25 {...props} />
        <Tooth26 {...props} />
        <Tooth27 {...props} />
        <Tooth28 {...props} />
        <Tooth29 {...props} />
        <Tooth30 {...props} />
        <Tooth31 {...props} />
        <Tooth32 {...props} />
        {designType.archChart && <Arches {...props} />}
        {designType.toothChart && numberingSystem === 'uns' && <UnsNumbers />}
        {designType.toothChart && numberingSystem === 'fdi' && <FdiNumbers />}
        {designType.toothChart && numberingSystem === 'palmer' && (
          <PalmerNumbers />
        )}
      </svg>
    </div>
  );
}

DentalChart.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  numberingSystem: PropTypes.string.isRequired,
  designType: PropTypes.object.isRequired,
  nested: PropTypes.bool,
};

export default DentalChart;
