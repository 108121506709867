import PropTypes from 'prop-types';

function Tooth27({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(27) : lowerArch;
  const variable = designType.toothChart ? 27 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[27]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M1220.4 4525.7c57.7-8.1 108.2-8.4 118 35.7l29.1 86.3c20.9 65.8-1 52.5-4.5 73.3l-43.2 50.7c-16.5 16.7-34.3 32.6-73.4 33.4 -57.2-2.1-115.1-3-127.4-85.7l-4.9-96.6C1126 4576.7 1139 4531.2 1220.4 4525.7L1220.4 4525.7z"
      />
      <path
        className="st1"
        d="M1149.5 4603.1c17 53.7 27.6 115.4 79.8 125.2 40.6 18.5 82.2 48.4 116.4-12.6"
      />
      <path
        className="st1"
        d="M1326.9 4686.3c-20.7 12.3-42.1 26.7-44.8-7.9 -3.7-36 11.8-52.6 18.3-78.4"
      />
      <path
        className="st1"
        d="M1185.7 4602.3c4.8-21.5 20.7-37.9 55.3-45.5"
      />
      <path
        className="st1"
        d="M1203.7 4650.6c11.8 11.2 23.6 2.1 35.4-32.7 6.2-26.4 17.1-33.6 27.7-42.2"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="1100"
          y="4550"
          width="240"
          height="240"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth27.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth27;
