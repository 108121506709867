import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';

import DesignTypeSelection from './DesignTypeSelection/DesignTypeSelection';
import FieldMetadata from './FieldMetadata/FieldMetadata';
import ManufacturerSelection from './ManufacturerSelection/ManufacturerSelection';

function CaseItemFields({
  customerLocation,
  index,
  parsedCase,
  toggleForceDesignApproval,
  labLocationGuideCategoryGroups = null,
}) {
  const { values, isValid, validateForm, setFieldTouched, setFieldValue } =
    useFormikContext();
  const { orderItems } = values;
  const { metaData } = parsedCase;
  const { isParsed } = metaData;
  const { priceList, assignedManufacturers, numberingSystem } =
    customerLocation;
  const { designTypes } = priceList;

  const initialDesignType = designTypes.find(
    (type) => type.id === orderItems[index].designTypeId,
  );
  let locationManufIds = [];
  if (initialDesignType && initialDesignType.disableInHouse === true) {
    locationManufIds = assignedManufacturers
      .filter((m) => m.manufacturerOrganization.isInHouse === false)
      .map((assignedManuf) => assignedManuf.manufacturerOrganization.id);
  }
  if (initialDesignType && initialDesignType.disableInHouse === false) {
    locationManufIds = assignedManufacturers.map(
      (assignedManuf) => assignedManuf.manufacturerOrganization.id,
    );
  }
  const aManufacturers = initialDesignType?.manufacturers
    ? initialDesignType.manufacturers.filter((manuf) =>
        locationManufIds.some((locManuf) => locManuf === manuf.id),
      )
    : [];
  const [
    { availableManufacturers, selectedDesignType, updatedAfterLocationsFetch },
    setState,
  ] = useState({
    selectedDesignType:
      orderItems[index].designTypeId !== '' ? initialDesignType : null,
    availableManufacturers: aManufacturers,
    updatedAfterLocationsFetch: false,
  });

  useEffect(() => {
    toggleForceDesignApproval();
    if (!isValid) {
      validateForm();
      values.orderItems.forEach((_item, index) => {
        setFieldTouched(`orderItems[${index}].designTypeId`, true);
        setFieldTouched(
          `orderItems[${index}].manufacturerOrganizationId`,
          true,
        );
      });
    }
  }, []);

  useEffect(() => {
    if (
      labLocationGuideCategoryGroups &&
      !updatedAfterLocationsFetch &&
      selectedDesignType
    ) {
      setSelectedDesignType(selectedDesignType.id);
      setState((state) => ({ ...state, updatedAfterLocationsFetch: true }));
    }
  }, [updatedAfterLocationsFetch, labLocationGuideCategoryGroups]);

  function setSelectedDesignType(id) {
    const selectedDesignType = designTypes.find((type) => type.id === id);
    let locationManufIds = [];
    if (selectedDesignType && selectedDesignType.disableInHouse === true) {
      locationManufIds = assignedManufacturers
        .filter((m) => m.manufacturerOrganization.isInHouse === false)
        .map((assignedManuf) => assignedManuf.manufacturerOrganization.id);
    }
    if (selectedDesignType && selectedDesignType.disableInHouse === false) {
      locationManufIds = assignedManufacturers.map(
        (assignedManuf) => assignedManuf.manufacturerOrganization.id,
      );
    }
    const availableManufacturers = selectedDesignType?.manufacturers
      ? selectedDesignType.manufacturers.filter((manuf) =>
          locationManufIds.some((locManuf) => locManuf === manuf.id),
        )
      : [];

    setState((state) => ({
      ...state,
      selectedDesignType,
      availableManufacturers,
    }));
    setTimeout(() => {
      if (selectedDesignType) {
        setFieldValue(
          `orderItems[${index}].designTypeName`,
          selectedDesignType.name,
        );
        setFieldValue(
          `orderItems[${index}].guideCodes`,
          determineCodes(selectedDesignType),
        );
      }
      setFieldValue(
        `orderItems[${index}].orderItemModifiersAttributes`,
        determineModifiers(selectedDesignType),
      );
      toggleForceDesignApproval();
    }, 100);
  }

  function determineModifiers(designType = {}) {
    const { modifierGroups = [] } = designType;

    return modifierGroups
      .map((group) => {
        const defaultMod = group.modifiers.find((mod) => mod.default === true);
        if (defaultMod) {
          return { modifierId: defaultMod.id };
        }
        return null;
      })
      .filter((g) => g !== null);
  }

  function determineCodes(designType) {
    const guideCategoryGroupsOfDesignType = labLocationGuideCategoryGroups.find(
      (item) => item.designTypeId === designType.id,
    );
    if (!Array.isArray(orderItems[index].guideCodes)) {
      return [];
    }
    let gc = [];
    if (orderItems[index].designTypeId !== designType.id) {
      gc = [...orderItems[index].guideCodes];
    }
    for (const categoryGroup of guideCategoryGroupsOfDesignType?.guideCategoryGroups ||
      []) {
      for (const guideCategory of categoryGroup.guideCategories || []) {
        for (const guideCode of guideCategory.guideCodes) {
          if (
            guideCode.default === true &&
            !gc.some(
              (item) =>
                item.codeId === guideCode.id ||
                item.categoryId === guideCode.guideCategoryId,
            )
          ) {
            gc.push(parseGuideCode(guideCategory, guideCode));
          }
        }
      }
    }

    return gc;
  }
  function parseGuideCode(guideCategory, guideCode) {
    return {
      categoryId: guideCode.guideCategoryId,
      isDefault: guideCode.default,
      codeId: guideCode.id,
      categoryName: guideCategory.name,
      showDropdown: guideCategory.showDropdown,
      codes: [guideCode.name],
      fileUrl: guideCode.imageUrl || guideCategory.imageUrl,
      categoryAdditionalInfo: {
        guideCategoryImages: guideCategory.guideCategoryImages,
        fullDescription: guideCategory.description,
      },
      description: guideCategory.showDropdown
        ? guideCode.description || guideCategory.short_description
        : guideCategory.short_description,
    };
  }

  function removeOrderItem(index) {
    const newOrderItems = values.orderItems.filter((_item, i) => i !== index);
    setFieldValue('orderItems', newOrderItems);
  }
  return (
    <>
      <DesignTypeSelection
        index={index}
        setSelectedDesignType={setSelectedDesignType}
        designTypes={designTypes}
        removeOrderItem={removeOrderItem}
        isParsed={isParsed}
      />

      <FieldMetadata
        orderItem={values.orderItems[index]}
        numberingSystem={numberingSystem}
        customerLocation={customerLocation}
        selectedDesignType={selectedDesignType}
        removeOrderItem={removeOrderItem}
        index={index}
        labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
        isParsed={isParsed}
      />
      <ManufacturerSelection
        index={index}
        availableManufacturers={availableManufacturers}
      />
    </>
  );
}

CaseItemFields.propTypes = {
  customerLocation: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  parsedCase: PropTypes.shape({
    metaData: PropTypes.shape({
      isParsed: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  toggleForceDesignApproval: PropTypes.func.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array,
};

export default memo(CaseItemFields);
