import PropTypes from 'prop-types';

function Tooth31({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart ? teeth.includes(31) : lowerArch;
  const variable = designType.toothChart ? 31 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[31]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  const paths = [
    "M594.6 3190.4c-32.1 14.9-64.9 24.4-95 56.6 -24.9 19.6-43.4 54.8-47.7 124.9 -2.1 49.2-14 97.2 18.6 150.9 28 23.6 10.8 31.6 86.9 71.8 39.2 16.4 67.3 52.6 127.5 31.4 29.1-8.2 39-5 88.9-25.6 21.1-4.8 38.7-16.9 48.4-44.7 10.5-18 19.3-42.9 25.2-79.2 -2-31.4-6.8-44.3-11-60.4 -6.8-42.2-4.3-59.8 0.2-72.4 0.5-21.3-3.8-42.5-17.1-63.8 -7-23-11.6-44.8-52.4-70C729.8 3181.9 705.7 3160.9 594.6 3190.4L594.6 3190.4z",
    "M665.1 3596.6c14.5-6.9 14.7-19 58.7-15.4 10.6-1.4 21.3-1.6 31.9-16.3l23.3-14.6 19.9-17.2c15.5-16.9 19.1-35.1 13.9-54.3 -2.7-21.3-7.1-47-3.3-50.9 2-13.2-2-34.6-6.7-56.9 2.2-28.8 22.6-24.8-4-105.2 -1.7-17.1-10.8-25.9-27.5-25.9 -13.6-2.7-17.3-9-34.4-22.5 -12.2-7.2-18.5-14.4-45.6-21.7 -20.7 2.6-41.4-4.3-62.1 12.8l-36.3 32.7c-29.3 24.3-32.4 37.7-34.6 50.8 -8.1 19.5-5 39.1-1.9 58.6 0.7 16.7 6.4 30.5 23.2 38 -8 9.6-17.1 14.2-20.8 45.6 -8.6 54.6 4.6 68.6 13.6 90.6 17.5 25.4 34.9 44.5 52.4 65.7",
    "M713.4 3252.8c-15.7 10.7-28 27.4-34.6 54.2 2.8 21 7.7 37.9 12.8 54.3 4.3 22.7 16.2 21.4 10.1 76.8 -10.8 10.4-13.4 18.2-10.4 24.1 19.5 41.5 12.7 78.1-18.4 110.3",
    "M742.9 3522.7c-9.6-20.8-20.5-40.9-48.1-52.7 -3.8-11.8-16.8-16.4-41.3-12.2",
    "M613.9 3384.4c39.1-3.3 73.5-3.8 85.3 8.8",
    "M797.5 3408.2c-30.6-8.5-51.8-25.3-99.1-19.2",
    "M786.4 3370.2l-42.3 22.3",
    "M617.6 3289.6c28.1 5.5 47.7 11 62 16.5",
    "M654.8 3238.9c11.1 21.9 25.1 43.7 23.1 65.6"
  ];

  return (
    <>
      {paths.map((d, index) => (
        <path key={index.toString()} className={index === 0 ? className : "st1"} d={d} cursor="pointer" pointerEvents="all" />
      ))}
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="470"
          y="3200"
          width="350"
          height="410"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth31.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.number).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth31;
