import PropTypes from 'prop-types';

function Tooth2({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 2)
    : upperArch;
  const variable = designType.toothChart ? 2 : 'upper';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[2]?.includes(guideCodeId);
  const className = `st2 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M470.9 2102c-20.2-27.6-17.5-67.8-3.9-114.1 51.9-118.8 145.1-144.4 290.3-52.8 45.5 18.4 81.6 53.7 105.4 89 23.4 34.7 45.1 51.4 33.5 112.8 -26.7 103.4-72.7 184.5-203.3 168.5 -51.9-6.3-97.7 7.3-163.3-44.1C504.3 2219.3 455.3 2222.1 470.9 2102L470.9 2102z"
      />
      <path
        className="st1"
        d="M530.5 1948.6c54.6-118.1 144-24.2 230.8 54.1 17.8 21.4 40.3 34.8 47.7 73.4 26.8 68-39.6 106.8-70.8 153.3s-63.4 69.7-119.9 51.8c-131.9-48.6-136.9-112.4-116.4-179.2 -41.7-98.9-13.4-104.1-2.5-132.4C509.8 1963.9 520.1 1961.3 530.5 1948.6L530.5 1948.6z"
      />
      <path
        className="st2"
        d="M740.2 2004.1c-13.3 7.2-22.8 7.1-32.4 7 -25.6-3.8-36.3 9.8-39.5 32.3 -0.3 14.1 3.4 29.7-18.4 36.6 -18.8 17.2-9.4 25-7.1 35.2 -0.5 16-1 26.7-1.5 35.2 -0.4 57.4 15.3 56.1 25.2 76.1 5.9 6.5 13.3 12.5 8.4 22.6l11.2 9.9"
      />
      <path
        className="st2"
        d="M653 1936.3c5.4 11.8 10.6 23.5 19.6 35.3 10 7.5 14.3 23.6 16.8 42.3"
      />
      <path
        className="st2"
        d="M553 1959.9c19 0.8 38 1.6 42.2 17 24 17.8 48.1 35.6 74.5 55.1"
      />
      <path
        className="st2"
        d="M517.4 2088l54.9 7.2c20.7 8.9 42.3 14.9 70.4 1.6"
      />
      <path
        className="st2"
        d="M625.4 2260.1l14.1-12.6c-3.9-36.1 3.3-41.6 14.2-36.6"
      />
      <path
        className="st2"
        d="M642.7 2120.7l29.6-2.7c13.1 9.1 26.3 14.4 39.4 19.8"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        cursor="pointer"
        aria-pressed={isActive}
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="450"
          y="1900"
          width="460"
          height="400"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth2.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth2;
