import {
  CreateNotification,
  handleTryCatch,
  overpanelCasesData,
} from '@fullcontour/common';
import updateCodeIdsSelectedTeethArch from './updateCodeIdsSelectedTeethArch';

const applySelectedCodes = handleTryCatch(
  async ({
    setLoading,
    values,
    orderItemIndex,
    createCode,
    setFieldValue,
    guideModalClose,
    applyToAll,
    designType,
  }) => {
    setLoading(true);

    const newGuidesData = values.orderItems[orderItemIndex].guideCodes.reduce(
      ({ errors, promises }, guideCode) => {
        const errorsMiddleware = { ...errors };
        if (
          guideCode.codeId.includes('other') &&
          guideCode.otherUnit !== 'id_tag'
        ) {
          if (!guideCode.isOtherValueExists) {
            const variables = {
              input: {
                input: {
                  guideCategoryId: guideCode.categoryId,
                  name: guideCode.codes[0],
                },
              },
            };
            promises.push(createCode({ variables }));
          } else {
            errorsMiddleware[guideCode.categoryName] = guideCode.codes[0];
          }
        }
        return { errors: errorsMiddleware, promises };
      },
      { errors: {}, promises: [] },
    );

    const { errors, promises } = newGuidesData;

    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((guideCategoryName) => {
        CreateNotification.createNotification({
          type: 'error',
          message: `Category '${guideCategoryName}' already has design guide '${errors[guideCategoryName]}'`,
        });
        return null;
      });

      setLoading(false);
      throw { hide: true };
    }

    const responses = await Promise.all(promises);

    const createdCodesIds = responses.reduce(
      (
        obj,
        {
          data: {
            createGuideCustomCode: { guideCustomCode },
          },
        },
      ) => {
        obj[guideCustomCode.guideCategoryId] = guideCustomCode.id;
        return obj;
      },
      {},
    );

    const updatedGuideCodes = values.orderItems[orderItemIndex].guideCodes.map(
      ({
        categoryId,
        categoryName,
        showDropdown,
        codes,
        description,
        fileUrl,
        isDefault,
        codeId,
        categoryAdditionalInfo,
      }) => ({
        categoryId,
        categoryName,
        showDropdown,
        codes,
        description,
        fileUrl,
        isDefault,
        categoryAdditionalInfo,
        codeId: codeId.includes('other') ? createdCodesIds[categoryId] : codeId,
      }),
    );

    updateCodeIdsSelectedTeethArch({
      updatedGuideCodes,
      setFieldValue,
      orderItemIndex,
      values,
    });

    setFieldValue(
      `orderItems[${orderItemIndex}].guideCodes`,
      updatedGuideCodes,
    );

    setLoading(false);
    guideModalClose();

    if (applyToAll) {
      overpanelCasesData({
        ...overpanelCasesData(),
        [designType.designTypeId]: values.orderItems[orderItemIndex].guideCodes,
      });
      CreateNotification.createNotification({
        type: 'info',
        message: `Selected Design Guides were applied to all ${designType.designTypeName} orders`,
      });
    }
    if (promises.length === 0) {
      throw { hide: true };
    }
    return null;
  },
  {
    successText: 'Guide Code was successfully created',
  },
);

export default applySelectedCodes;
