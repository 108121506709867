import PropTypes from 'prop-types';

function Tooth21({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  lowerArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 21)
    : lowerArch;
  const variable = designType.toothChart ? 21 : 'lower';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[21]?.includes(guideCodeId);
  const className = `st1 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M2688.1 4365.1c33.6 46.3 41.6 79.5 38.2 106.8 -10 47.5-15.2 98.6-65.2 117.4 -79.8 26.6-96.9 4.5-129.3-5.7 -37.1-18-66.2-40.6-87.2-67.9 -16.2-11.2-15-22.4-15.6-33.6 2.5-20.8-2-34.9-3.6-51.7 -2.4-32.7 11.7-55.5 19.6-81.9 23.5-19.1 40.7-45.4 79.2-47.6l66.4-1.9C2631.4 4296.8 2659.2 4332.3 2688.1 4365.1L2688.1 4365.1z"
      />
      <path
        className="st1"
        d="M2670.8 4374.6c-11.9 16.7-26.6 36.3-34.4 46.6 -4.2 7.4-0.7 12.6-1.7 24.1 5 30-4.3 28.1-12.8 34.5 -8.7 6.7-17.6 13-24.1 24.2 -7.4 22.4-17.9 17.4-27.5 20.8l-39.6 7c-31.4 9.5-36.4-5-53.5-5.9"
      />
      <path
        className="st1"
        d="M2486.6 4476c-2.1 11.4 5.4 24.2-11.1 33.6"
      />
      <path
        className="st1"
        d="M2572 4488.7l-73.5-87.7c-15.4-18.7-27.3-23.2-28.4 13.9"
      />
      <path
        className="st1"
        d="M2583.9 4410.2c-7.2-22.1-27.5-44.2-62.2-66.2 13.5 4.7 26.9 9.5 44.8 0.7 21.2-10.4 31.4 1.6 44 8.5"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="2450"
          y="4330"
          width="250"
          height="250"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth21.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  lowerArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth21;
