import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ModifierModal from './ModifierModal';
import ModifierSelectionPopup from './ModifierSelectionPopup';

function ModifierSelection({ selectedDesignType = null, index }) {
  const { values } = useFormikContext();
  const { orderItems = [] } = values;

  return selectedDesignType ? (
    <div>
      <ModifierSelectionPopup
        modifierGroups={selectedDesignType.modifierGroups}
        currentOrderItem={orderItems[index]}
        index={index}
      />
      <ModifierModal />
    </div>
  ) : null;
}

ModifierSelection.propTypes = {
  selectedDesignType: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default ModifierSelection;
