/* eslint-disable react/state-in-constructor */
import {
  DesignGuideOrderProvider,
  GuideModal,
  ModifierOrderProvider,
  withFormWrap,
} from '@fullcontour/common';
import { FieldArray, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import CaseItem from '../../Overpanel/OverpanelForm/CaseItem/CaseItem';
import '../../Overpanel/OverpanelForm/OverpanelForm.css';
import { assignTurnaroundTimes } from '../../Overpanel/OverpanelForm/helpers';
import { TempOrderActions } from './TempOrderActions';
import { TempOrderCase } from './TempOrderCase';
import { initialValues, schema, withCreate } from './helpers';

function TempOrderForm(props) {
  const {
    customerLocation,
    customerLocation: {
      priceList: { designTypes, priceListItems, turnaroundTimes },
    },
    labLocationGuideCategoryGroups,
    // handleSubmit,
    fileIndex,
    tempOrder,
    values,
    values: { orderItems, id },
    setFieldValue,
    // isValid,
    validateForm,
  } = props;

  const [forceDesignApproval, setForceDesignApproval] = useState(false);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [designGuideCategories, setDesignGuideCategories] = useState(null);
  const [orderItemIndex, setOrderItemIndex] = useState(null);
  const [currentOrderItem, setCurrentOrderItem] = useState(null);
  const [defaultTurnaroundTime, setDefaultTurnaroundTime] = useState({});
  const [filteredTurnaroundTimes, setFilteredTurnaroundTimes] = useState([]);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [modifierGroups, setModifierGroups] = useState([]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  function toggleForceDesignApproval() {
    const force = checkDesignTypesForApproval();
    setForceDesignApproval(force);
    filterTurnaroundTimes();
  }

  function checkDesignTypesForApproval() {
    const designTypeIds = orderItems.map((item) => item.designTypeId);
    const activeDesignTypes = designTypes.filter((designType) =>
      designTypeIds.some((id) => id === designType.id),
    );
    return activeDesignTypes.some(
      (designType) => designType.designApproval === true,
    );
  }
  function filterTurnaroundTimes() {
    const turnaroundData = assignTurnaroundTimes(
      orderItems,
      priceListItems,
      turnaroundTimes,
      designTypes,
    );
    setDefaultTurnaroundTime(turnaroundData.defaultTurnaroundTime);
    setFilteredTurnaroundTimes(turnaroundData.filteredTurnaroundTimes);
    setFieldValue(
      `turnaroundTimeId${id}`,
      turnaroundData.defaultTurnaroundTime.id,
    );
  }

  function guideModalOpen(
    designGuideCategories,
    orderItemIndex,
    currentOrderItem,
  ) {
    setDesignGuideCategories(designGuideCategories);
    setOrderItemIndex(orderItemIndex);
    setCurrentOrderItem(currentOrderItem);
    setShowGuideModal(true);
  }

  function guideModalClose() {
    setDesignGuideCategories(null);
    setOrderItemIndex(null);
    setCurrentOrderItem(null);
    setShowGuideModal(false);
  }

  function modifierModalOpen(groups, orderItemIndex, currentOrderItem) {
    setModifierGroups(groups);
    setShowModifierModal(true);
    setOrderItemIndex(orderItemIndex);
    setCurrentOrderItem(currentOrderItem);
  }

  function modifierModalClose() {
    setModifierGroups([]);
    setShowModifierModal(false);
    setOrderItemIndex(null);
    setCurrentOrderItem(null);
  }

  return (
    <DesignGuideOrderProvider
      value={{
        guideModalClose,
        guideModalOpen,
        showGuideModal,
        designGuideCategories,
        orderItemIndex,
        currentOrderItem,
        customerLocation,
        designTypes,
        allowNested: customerLocation?.allowNested,
      }}
    >
      <ModifierOrderProvider
        value={{
          modifierModalClose,
          modifierModalOpen,
          showModifierModal,
          modifierGroups,
          orderItemIndex,
          currentOrderItem,
        }}
      >
        <form
          className="columns caseRowForm"
          noValidate
          style={{
            backgroundColor:
              fileIndex % 2 === 0 ? '#fff' : 'rgba(251, 251, 251, 0.90)',
          }}
        >
          <GuideModal
            values={values}
            labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
            setFieldValue={setFieldValue}
            allowNested={customerLocation?.allowNested}
            hideApplyAll
          />

          <FieldArray
            name="orderItems"
            render={(arrayHelpers) => (
              <>
                <TempOrderCase
                  parsedCase={tempOrder}
                  arrayHelpers={arrayHelpers}
                  forceDesignApproval={forceDesignApproval}
                  turnaroundTimes={filteredTurnaroundTimes}
                  defaultTurnaroundTime={defaultTurnaroundTime}
                  pendingCases
                  {...props}
                />
                <CaseItem
                  arrayHelpers={arrayHelpers}
                  toggleForceDesignApproval={toggleForceDesignApproval}
                  parsedCase={tempOrder}
                  {...props}
                />

                <TempOrderActions
                  arrayHelpers={arrayHelpers}
                  tempOrder={tempOrder}
                  {...props}
                />
              </>
            )}
          />
        </form>
      </ModifierOrderProvider>
    </DesignGuideOrderProvider>
  );
}

TempOrderForm.propTypes = {
  customerLocation: PropTypes.object.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fileIndex: PropTypes.number.isRequired,
  tempOrder: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  validateForm: PropTypes.func.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const valuesToSend = Object.entries(values).reduce(
        (obj, [key, value]) => {
          const updatedObj = Object.assign({}, obj);
          if (key.includes(values.id) && key !== values.id) {
            updatedObj[key.slice(0, -36)] = value;
          } else if (
            ![
              'turnaroundTimeId',
              'requiresDesignApproval',
              'instructions',
            ].includes(key)
          ) {
            updatedObj[key] = value;
          }

          return updatedObj;
        },
        {},
      );

      valuesToSend.selectedCodes = undefined;
      const variables = {
        variables: {
          input: { input: valuesToSend, clientMutationId: uuidv4() },
        },
      };

      props.create(variables);

      setSubmitting(false);
    },
  }),
)(memo(TempOrderForm));
