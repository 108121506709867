import PropTypes from 'prop-types';
import { DentalChart } from '../DentalChart';

function ChartSelectionPopup({
  toggleArch,
  toggleTooth,
  orderItem,
  numberingSystem,
  designType,
  nestedTeeth = {},
  guideCodeId = null,
  nestedArches = { upper: [], lower: [] },
  nested = false,
}) {
  return orderItem ? (
    <div className="modal-card-body is-flex is-flex-direction-column is-justify-content-center">
      <DentalChart
        toggleArch={toggleArch}
        toggleTooth={toggleTooth}
        upperArch={orderItem.arches.some((arch) => arch === 'upper')}
        lowerArch={orderItem.arches.some((arch) => arch === 'lower')}
        teeth={orderItem.teeth}
        numberingSystem={numberingSystem}
        designType={designType}
        nestedTeeth={nestedTeeth}
        nestedArches={nestedArches}
        guideCodeId={guideCodeId}
        nested={nested}
      />
    </div>
  ) : null;
}

ChartSelectionPopup.propTypes = {
  numberingSystem: PropTypes.string.isRequired,
  guideCodeId: PropTypes.string,
  toggleArch: PropTypes.func.isRequired,
  toggleTooth: PropTypes.func.isRequired,
  orderItem: PropTypes.object.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  nestedArches: PropTypes.object,
  nested: PropTypes.bool,
};

export default ChartSelectionPopup;
