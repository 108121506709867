import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function HeaderInfoId(props) {
  const { hexOrderCode, redesignCount, isSubscribed, source } = props;

  const appName = import.meta.env.VITE_APP_NAME;

  const { t } = useTranslation('common');

  return (
    <>
      <p
        className="has-text-info"
        style={{
          marginBottom: 0,
          display: 'inline-block',
          marginRight: '1.0em',
        }}
      >
        <small>
          <span style={{ color: '#666' }}>{t('Case')} ID: </span>
          &nbsp;
          <b style={{ textTransform: 'uppercase' }}>#{hexOrderCode}</b>
        </small>
        &nbsp;
        {source && appName === 'admin_portal' && (
          <small>
            <span style={{ color: '#666' }}>{t('Source')}: </span>
            &nbsp;
            <b>{source.charAt(0).toUpperCase() + source.slice(1)}</b>
          </small>
        )}
      </p>
      {redesignCount > 0 && (
        <span className="tag is-danger">
          {t('Redesign')}: {redesignCount}
        </span>
      )}
      {isSubscribed && (
        <span className="tag is-primary">{t('Subscribed')}</span>
      )}
    </>
  );
}

HeaderInfoId.propTypes = {
  hexOrderCode: PropTypes.string.isRequired,
  redesignCount: PropTypes.number.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  source: PropTypes.string,
};

export default HeaderInfoId;
