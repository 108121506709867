import PropTypes from 'prop-types';

function Tooth13({
  toggleTooth,
  teeth,
  designType,
  toggleArch,
  upperArch,
  nestedTeeth = {},
  guideCodeId = null,
}) {
  const isActive = designType.toothChart
    ? teeth.some((tooth) => Number(tooth) === 13)
    : upperArch;
  const variable = designType.toothChart ? 13 : 'upper';
  const handleClick = () => (designType.toothChart ? toggleTooth(variable) : toggleArch(variable));
  const isSelected = nestedTeeth[13]?.includes(guideCodeId);
  const className = `st2 ${isActive ? `activeTooth ${isSelected ? 'toothSelected' : ''}` : ''}`;

  return (
    <>
      <path
        className={className}
        d="M2822.8 1228.7c97.8 13.9 144.1 64.4 114.5 168.7 -31.2 38.3-59.5 79.4-112.4 96.1 -18.7-0.4-35.2 1.5-46.5 8.6 -16.2 4.4-34.8 4.4-54.9 1.6 -37.8-20.1-88.7-3.4-110-70.1 -17.6-53.4-14.6-100.4 27.8-135.3C2725.1 1228.1 2771.2 1234 2822.8 1228.7L2822.8 1228.7z"
      />
      <path
        className="st2"
        d="M2798.9 1235.8c12.4 14.4 23.9 27.9 19.3 43.4 5.4 17.9 5.4 23.2 6.3 29.8 7.7 14.2 8.2 17.9 8.5 21.1 -0.3 24.7-4.4 30.2-8.4 36.6 -11.2 32.7-0.9 39.6 5.8 50.7 6.8 11 16.3 5.6 19.8 36.6"
      />
      <path
        className="st2"
        d="M2726.2 1459.9c47.5-16.4 50.5-41.7 42.1-69.1 5-44.2-13-69.9-25.6-99.9"
      />
      <path
        className="st2"
        d="M2699.1 1310.7c19.7 2.4 39.4 3 59.2 12.5"
      />
      <path
        className="st2"
        d="M2790.5 1282.3c-21.5 18.5-28.2 33.2-35.1 48"
      />
      <path
        className="st2"
        d="M2822 1455.4c-9.1-9.3-25.8-23.1-53.6-43.5"
      />
      <path
        className="st2"
        d="M2746.9 1492.5c-37.3-25.4-87.2-4.1-103.7-80.8 -2.9-24.4-19.6-53.3 18.7-109.5"
      />
      <g
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-pressed={isActive}
        cursor="pointer"
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        className="clickableArea"
      >
        <rect
          x="2610"
          y="1220"
          width="330"
          height="270"
          fill="transparent"
          strokeWidth={0}
        />
      </g>
    </>
  );
}

Tooth13.propTypes = {
  toggleTooth: PropTypes.func.isRequired,
  teeth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  designType: PropTypes.shape({
    toothChart: PropTypes.bool,
  }).isRequired,
  nestedTeeth: PropTypes.object,
  toggleArch: PropTypes.func.isRequired,
  upperArch: PropTypes.bool.isRequired,
  guideCodeId: PropTypes.string,
};

export default Tooth13;
