import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ReviewModalBodyTerms(props) {
  const {
    order: {
      location: { subType },
    },
    toggleTerms,
  } = props;

  const { t } = useTranslation('formfields');
  const [checked, setChecked] = useState(false);

  const handleTerms = (e) => {
    toggleTerms(e);
    setChecked((prev) => !prev);
  };

  const preFix =
    subType === 'laboratory'
      ? 'I have viewed and evaluated this case and approve the design as is.'
      : 'I am the prescribing Dr of this case. I have viewed and evaluated this case and approve the design as is.';

  return (
    <div className="is-flex is-flex-direction-row is-align-items-center">
      <input
        id="toggleTerms"
        name="toggleTerms"
        type="checkbox"
        className="is-checkradio is-primary"
        onChange={handleTerms}
        checked={checked}
      />
      <label className="checkbox mt-0 pt-0 pl-6" htmlFor="toggleTerms">
        {t(preFix)}
        &nbsp;
        {t(
          "I understand 3Shape Design Services's Terms and Conditions as stated",
        )}
        &nbsp;
        <a href="/legal" target="_blank" rel="noreferrer">
          {t('here')}
        </a>
        .
      </label>
    </div>
  );
}

ReviewModalBodyTerms.propTypes = {
  order: PropTypes.object.isRequired,
  toggleTerms: PropTypes.func.isRequired,
};

export default ReviewModalBodyTerms;
