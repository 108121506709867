import { Dropdown } from '@fullcontour/common';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function ModifierModalDropdown(props) {
  const { arrayHelpers, modifiers, group, orderItemIndex } = props;

  const { values, setFieldValue } = useFormikContext();

  const { t } = useTranslation('formfields');
  const placeholder = t('Select...');

  let value = '';
  const modifierIds = modifiers.map((mod) => mod.id);
  const existingModifier = values.orderItems[
    orderItemIndex
  ].orderItemModifiersAttributes.findIndex((attr) =>
    modifierIds.some((mId) => mId === attr.modifierId),
  );

  if (existingModifier !== -1) {
    value =
      values.orderItems[orderItemIndex].orderItemModifiersAttributes[
        existingModifier
      ].modifierId;
  }

  function handleChange(e) {
    if (existingModifier !== -1 && e.target.value) {
      setFieldValue(
        `orderItems[${orderItemIndex}].orderItemModifiersAttributes[${existingModifier}].modifierId`,
        e.target.value,
      );
    }

    if (existingModifier !== -1 && (!e.target.value || e.target.value === '')) {
      arrayHelpers.remove(existingModifier);
    }

    if (existingModifier === -1) {
      arrayHelpers.push({
        modifierId: e.target.value,
      });
    }
  }

  const scrubbedModifiers = modifiers.map((m) => {
    const { default: isDefault, ...rest } = m;
    return { ...rest };
  });

  return (
    <Dropdown
      id={group.id}
      name={group.id}
      hideLabel
      options={scrubbedModifiers}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      search
    />
  );
}

ModifierModalDropdown.propTypes = {
  modifiers: PropTypes.array.isRequired,
  group: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  orderItemIndex: PropTypes.number.isRequired,
};

export default memo(ModifierModalDropdown);
