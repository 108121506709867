import { useQuery } from '@apollo/client';
import { Drawer, FormFieldArray, LoadingOrError } from '@fullcontour/common';
import {
  CREATE_SIMPLE_ORDER,
  GET_DESIGN_TYPES,
  GET_DESIGN_TYPE_CATS,
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
  GET_PRICE_LIST_OVERPANEL,
  GET_PRINTERS,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import './ReviewModalStyles.scss';
import {
  ChartSelection,
  GuideSelection,
  ModifierSelection,
} from './components';
import BusinessUnitsField from './components/BusinessUnitsField/BusinessUnitsField';
import CaseFoldersField from './components/CaseFoldersField/CaseFoldersField';
import CaseNameInput from './components/CaseNameInput/CaseNameInput';
import DesignTypesDropdown from './components/DesignTypesDropdown/DesignTypesDropdown';
import ManufacturerSelection from './components/ManufacturerSelection/ManufacturerSelection';
import TurnAroundTimesDropdown from './components/TurnAroundTimesDropdown/TurnAroundTimesDropdown';
import { initialValues, schema } from './helpers';
import fillDesignTypes from './helpers/functions';

function OrderForm({ currentUser }) {
  const [isFormReady, setIsFormReady] = useState(false);
  const [designTypes, setDesignTypes] = useState({});
  const { data, loading, error } = useQuery(GET_PRICE_LIST_OVERPANEL, {
    fetchPolicy: 'cache-and-network',
  });
  const designTypesQuery = useQuery(GET_DESIGN_TYPES);
  const designTypeCategoriesQuery = useQuery(GET_DESIGN_TYPE_CATS);
  const printersQuery = useQuery(GET_PRINTERS);
  const labLocationGuideCategoryGroupsQuery = useQuery(
    GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
  );
  const customSchema = useMemo(() => schema({ designTypes }), [designTypes]);
  const [open, setOpen] = useState(false);
  const [drawerBody, setDrawerBody] = useState(null);

  useEffect(() => {
    if (
      !(
        designTypesQuery.loading ||
        designTypeCategoriesQuery.loading ||
        printersQuery.loading ||
        labLocationGuideCategoryGroupsQuery.loading ||
        loading
      ) &&
      data
    ) {
      const designTypesData = designTypesQuery.data?.designTypes || [];
      const designTypeCategoriesData =
        designTypeCategoriesQuery.data?.designTypeCategories || [];
      const designTypesDropdownData = fillDesignTypes(
        designTypesData,
        designTypeCategoriesData,
        data.customerLocation.priceList.designTypes,
      );
      setDesignTypes({ ...designTypesDropdownData });
      setIsFormReady(true);
    }
  }, [
    designTypesQuery.loading,
    designTypeCategoriesQuery.loading,
    printersQuery.loading,
    labLocationGuideCategoryGroupsQuery.loading,
    loading,
  ]);

  if (!isFormReady) {
    return (
      <LoadingOrError
        error={
          designTypesQuery.error ||
          designTypeCategoriesQuery.error ||
          error ||
          printersQuery.error ||
          labLocationGuideCategoryGroupsQuery.error
        }
        loading={!isFormReady}
        title="Case Order Form"
      />
    );
  }
  return (
    <>
      <FormFieldArray
        formTitle="Create New Case"
        actions={{
          create: {
            submitButtonText: 'Review Case Details',
            query: CREATE_SIMPLE_ORDER,
          },
        }}
        dataSources={{
          currentUser,
          customerLocation: data.customerLocation,
          helperDrawer: {
            setOpen,
            setDrawerBody,
          },
        }}
        initialValues={initialValues}
        components={{
          businessUnitCards: BusinessUnitsField,
          caseNameInput: CaseNameInput,
          chartSelection: ChartSelection,
          guideSelection: GuideSelection,
          modifierSelection: ModifierSelection,
          designTypesDropdown: DesignTypesDropdown,
          turnAroundTimesDropdown: TurnAroundTimesDropdown,
          manufacturerSelection: ManufacturerSelection,
          caseFoldersField: CaseFoldersField,
        }}
        sections={{
          'Step 1': 'What would you like to order?',
          'Step 2': 'Case Information',
          'Step 3': 'Case Files',
          'Step 4': 'Production Options',
          // 'Step 5': 'Items',
        }}
        validationSchema={customSchema}
        stepperExceptions={[
          'orderItems[0].teethChart',
          'orderItems[0].guideCodes',
          'orderItems[0].orderItemModifiersAttributes',
          'instructions',
        ]}
        linkNames={{
          'orderItems[0].teethChart': [
            'orderItems[0].arches',
            'orderItems[0].teeth',
          ],
        }}
        fieldsConfig={[
          {
            id: 1,
            componentName: 'businessUnitCards',
            name: 'businessUnitName',
            placeholder: '',
            initialValue: initialValues.businessUnitName,
            componentProps: { data: { designTypes } },
            sectionName: 'Step 1',
          },
          {
            id: 2,
            name: 'orderItems[0].designTypeId',
            componentName: 'designTypesDropdown',
            labelText: 'Choose your indication',
            initialValue: initialValues.orderItems[0].designTypeId,
            componentProps: { data: { designTypes } },
            sectionName: 'Step 1',
          },
          {
            id: 3,
            componentName: 'caseNameInput',
            name: 'name',
            placeholder: '',
            initialValue: initialValues.name,
            labelText: 'Case Name',
            sectionName: 'Step 2',
          },
          {
            id: 4,
            name: 'orderItems[0].teethChart',
            componentName: 'chartSelection',
            labelText: 'Select your Teeth/Arches',
            initialValue: '',
            componentProps: { data: { designTypes } },
            sectionName: 'Step 2',
          },
          {
            id: 5,
            name: 'orderItems[0].guideCodes',
            componentName: 'guideSelection',
            labelText:
              'Design Guide Codes are a visual representation of instructions for design. Add or remove Design Codes below to ensure that we design the order according to your needs.',
            initialValue: initialValues.orderItems[0].guideCodes,
            componentProps: {
              designTypes,
              labLocationGuideCategoryGroups:
                labLocationGuideCategoryGroupsQuery.data
                  .labLocationGuideCategoryGroups,
            },
            sectionName: 'Step 2',
            helperContent: (
              <>
                <p>
                  Design Guide Codes provide a visual representation of design
                  instructions. To view the available codes, click the &quot;Add
                  Codes&quot; button. By doing this, you add much clearer
                  instructions to your case, ensuring the 3Shape Design Services
                  team designs the case according to your specifications.
                </p>
                <br />
                <p>
                  To learn more about Design Guide Codes, watch the video below.
                </p>
                <video
                  src="https://fc2-production.s3.us-west-2.amazonaws.com/assets/videos/How+to+Use+Design+Guide+Codes+(4).mp4"
                  width="100%"
                  className="mt-4"
                  preload="auto"
                  controls
                  style={{ border: '1px solid #dddddd' }}
                />
              </>
            ),
          },
          {
            id: 6,
            name: 'orderItems[0].orderItemModifiersAttributes',
            componentName: 'modifierSelection',
            labelText:
              'Select from optional Add-ons that go above and beyond designing your case',
            initialValue:
              initialValues.orderItems[0].orderItemModifiersAttributes,
            componentProps: { data: { designTypes } },
            sectionName: 'Step 2',
          },
          {
            id: 7,
            componentName: 'turnAroundTimesDropdown',
            name: 'turnaroundTimeId',
            placeholder: '',
            initialValue: initialValues.turnaroundTimeId,
            labelText: 'How quickly would you like your design completed?',
            sectionName: 'Step 2',
            componentProps: { data: { designTypes } },
          },
          {
            id: 8,
            componentName: 'input',
            name: 'instructions',
            placeholder: '',
            initialValue: initialValues.instructions,
            labelText: 'Additional case comments',
            sectionName: 'Step 2',
          },
          {
            id: 9,
            componentName: 'caseFoldersField',
            name: 'files',
            placeholder: '',
            initialValue: initialValues.files,
            sectionName: 'Step 3',
            componentProps: {
              designTypes,
            },
          },
          {
            id: 10,
            name: 'orderItems[0].manufacturerOrganizationId',
            componentName: 'manufacturerSelection',
            labelText: 'Choose your manufacturing destination',
            initialValue:
              initialValues.orderItems[0].manufacturerOrganizationId,
            sectionName: 'Step 4',
          },
        ]}
        key="Custom FieldArray"
        formReviewModal={{
          render: (values) => {
            const {
              name,
              instructions,
              businessUnitName,
              orderItems,
              turnaroundTimeId,
              files,
            } = values;
            const designType = designTypes[businessUnitName]?.find(
              ({ id }) => id === orderItems[0]?.designTypeId,
            )?.name;

            const teeths = values.orderItems[0].teeth.join(', ');
            const arches = values.orderItems[0].arches.join(', ');

            const turnaroundTime =
              data.customerLocation?.priceList?.turnaroundTimes.find(
                ({ id }) => id === turnaroundTimeId,
              )?.name;
            const modifierGroups = orderItems[0].orderItemModifiersAttributes
              .filter((addon) => typeof addon === 'object')
              .reduce((reducedModifiers, { modifierId }) => {
                const designType =
                  data.customerLocation.priceList.designTypes.find(
                    ({ id }) => id === orderItems[0].designTypeId,
                  );
                const modifierGroup = designType.modifierGroups.find(
                  ({ modifiers }) =>
                    modifiers.find(({ id }) => id === modifierId),
                );
                const selectedModifier = modifierGroup.modifiers.find(
                  ({ id }) => id === modifierId,
                );
                reducedModifiers.push({
                  modifierGroupLabel: modifierGroup.name,
                  ...selectedModifier,
                });
                return reducedModifiers;
              }, []);

            return (
              <>
                <div className="section">
                  <h2 className="sectionTitle">Case Name</h2>
                  <p className="sectionContent">{name}</p>
                </div>
                <div className="section">
                  <h2 className="sectionTitle">Indication</h2>
                  <p className="sectionContent">
                    <span style={{ fontWeight: 'bold' }}>{designType}</span>
                    {arches && <span> - Arches: {arches}</span>}
                    {teeths && <span> - Teeth #s: {teeths}</span>}
                  </p>
                </div>
                {orderItems[0].guideCodes.length > 0 && (
                  <div className="section">
                    <h2 className="sectionTitle">Design Guide Codes</h2>
                    <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                      <p>
                        {orderItems[0].guideCodes.map(
                          ({ categoryName, codes, fileUrl }) => (
                            <div
                              className="card mt-1 mb-1"
                              key={categoryName + codes[0]}
                            >
                              <div className="card-content">
                                <div className=" media">
                                  <div className="media-left">
                                    <figure className="image is-64x64">
                                      <img src={fileUrl} alt={categoryName} />
                                    </figure>
                                  </div>
                                  <div className="media-content">
                                    <p className="title is-6">{categoryName}</p>
                                    <p className="subtitle is-6">{codes[0]}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                      </p>
                    </div>
                  </div>
                )}
                {modifierGroups.length > 0 && (
                  <div className="section">
                    <h2 className="sectionTitle">Add-ons:</h2>
                    {modifierGroups.map(({ name, modifierGroupLabel }) => (
                      <p className="sectionContent" key={name}>
                        <span style={{ fontWeight: 'bold' }}>
                          {modifierGroupLabel}
                        </span>
                        - {name}
                      </p>
                    ))}
                  </div>
                )}
                <div className="section">
                  <h2 className="sectionTitle">Turnaround Time</h2>
                  <p className="sectionContent">{turnaroundTime}</p>
                </div>
                {instructions && (
                  <div className="section">
                    <h2 className="sectionTitle">Additional Case Comments</h2>
                    <p className="sectionContent">{instructions}</p>
                  </div>
                )}
                <div className="section">
                  <h2 className="sectionTitle">Files</h2>
                  <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                    {files
                      .filter((item) => typeof item === 'object')
                      .map(({ originalFileName }) => (
                        <div
                          key={originalFileName}
                          className="card m-1"
                          style={{ border: '1px solid black' }}
                        >
                          <p className="p-2">{originalFileName}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="section">
                  <h2 className="sectionTitle">Manufacturer</h2>
                  <p className="sectionContent">
                    {
                      data.customerLocation.assignedManufacturers.find(
                        ({ manufacturerOrganization: { id } }) =>
                          id === orderItems[0].manufacturerOrganizationId,
                      )?.manufacturerOrganization?.name
                    }
                  </p>
                </div>
              </>
            );
          },
          showModalOnSubmit: true,
          buttontext: 'Create Case',
          modalTitle: 'Review Case Details',
        }}
        showNextOnComplete
      />
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        direction="right"
        size={420}
        enableOverlay
      >
        {drawerBody}
      </Drawer>
    </>
  );
}

OrderForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
};
export default memo(OrderForm);
