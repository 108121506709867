import { gql } from '@apollo/client';

export default gql`
  query orderApprovalLog($id: ID!) {
    orderApprovalLog(id: $id) {
      id
      doctorApproval
      doctorEmail
      doctorIp
      doctorName
      dentalNotation
      doctorAcceptedAt
      doctorRejected
      order {
        id
        name
        redesignCount
        instructions
        state
        hexOrderCode
        requiresDesignApproval
        location {
          id
          name
          website
          logoUrl
          approvalDownloadStl
          approvalTerms
        }
        designUserMessages {
          id
          message
          holdMessage
          redesignCount
        }
        alignerManufacturerFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        convertedTreatmentReviewFiles {
          category
          downloadUrl
          createdAt
          fileType
          fileUrl
          id
          latestVer
          orderId
          originalFileName
          redesignCount
          s3Key
          sortOrder
          updatedAt
          ver
        }
        treatmentReviewFiles {
          downloadUrl
          category
          createdAt
          fileType
          fileUrl
          id
          latestVer
          orderId
          originalFileName
          redesignCount
          s3Key
          sortOrder
          updatedAt
          ver
        }
        feedbacks {
          id
          userId
          redesignCount
          redesignReason
          rating
          answers {
            orderItem {
              id
              designTypeName
              designTypeId
            }
            customReason
            id
            rating
            options {
              id
              name
              category {
                name
                id
              }
            }
          }
        }
        orderItems {
          id
          modifiers {
            name
          }
          designType {
            id
            name
            showStlFiles
            clearAlignerDesignType
            feedbackCategories {
              id
              name
              options {
                id
                name
              }
            }
          }
        }
        communicateAccount {
          id
          isGuided
        }
        finishedScans {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        attachments {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
        }
        objFiles {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
          category
          downloadUrl
          sortOrder
          createdAt
          redesignCount
        }
        stlFiles {
          id
          fileUrl
          s3Key
          ver
          redesignCount
          latestVer
          originalFileName
          downloadUrl
        }
        qualityControlImages {
          id
          fileUrl
          s3Key
          ver
          latestVer
          originalFileName
          downloadUrl
        }
      }
    }
  }
`;
